import { Column } from '@ant-design/charts';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  impactContainer: {
    fontSize: 24,
    textAlign: 'left',
    borderRadius: '16px',
    border: '2px solid #00DD9E',
    marginTop: 20,
    paddingTop: 32,
    fontWeight: 700,
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  title: {
    fontFamily: 'MontserratBold',
    color: '#00DD9E',
    fontSize: '50px',
    lineHeight: '63px',
    fontWeight: 700,
    textAlign: 'left',
    padding: 0,
    margin: 0,
    '@media screen and (max-width:280px)': {
      fontSize: '40px',
    },
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: 20,
    lineHeight: '28px',
    color: theme.palette.text.tertiary,
    cursor: 'pointer',
    pading: 0,
    margin: 0,
  },
  textData: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    pading: 0,
    margin: 0,
    fontWeight: 300,
  },
  dataTable: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: '10px',
  },
  dataDiv: {
    marginTop: 10,
  },
  impactRoot: {
    height: '100%',
  },
}));
export default useStyles;
