import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  containerImpact: {
    marginBottom: 100,
  },

  gridImpact: {
    borderLeft: '9px solid #D9D9D9',
    borderRight: '9px solid #D9D9D9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      borderTop: '9px solid #D9D9D9',
      borderBottom: '9px solid #D9D9D9',
      borderLeft: 'none',
      borderRight: 'none',
    },
  },

  gridImpactRigth: {
    borderRight: '9px solid #D9D9D9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      borderBottom: '9px solid #D9D9D9',
      borderRight: 'none',
    },
  },
  gridRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  containerTextImpact: {
    width: '200px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  subtitleImpact: {
    fontFamily: 'Montserrat',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: theme.palette.text.denary,
    wordBreak: 'break-word',
  },
  bodyImpact: {
    fontFamily: 'MontserratRegular',
    fontSize: '25px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: theme.palette.text.denary,
    wordBreak: 'break-word',
  },
  titleImpact: {
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    fontWeight: 700,
    lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#00DD9E;',
    marginBottom: 40,
  },
}));

export default useStyles;
