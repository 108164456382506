import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    // display: 'flex',
    textAlign: 'left',
    boxShadow: 'none',
    borderRadius: '10px',
    height: '580px',
    width: '100%',
    padding: '15px',
    backgroundColor: theme.palette.background.default,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.text.nonary,
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '56px',
    lineHeight: '64px',
    letterSpacing: '-2px',
    // marginBottom: '18px',
    marginTop: 48,
  },
  title2: {
    textAlign: 'center',
    color: '#06E89F',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '56px',
    lineHeight: '64px',
    letterSpacing: '-2px',
    marginTop: 48,
  },
  subtitle: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '25px',
    lineHeight: '27.5px',
    // color: '#082F5E',
    color: theme.palette.text.nonary,
    textAlign: 'left',
  },
  img: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
    borderRadius: '0px',
    '@media screen and (min-width:576px)': {
      backgroundPosition: '50% 18%',
    },
  },
  content: {
    height: '180px',
  },
  button: {
    // display: 'flex',
    justifyContent: 'center',
    height: '50px',
  },
  link: {
    textDecoration: 'none',
    textDecorationLine: 'none',
  },
}));

export default useStyles;
