import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonTransparent: {
    fontFamily: 'MontserratBold',
    backgroundColor: 'rgba(255, 255, 255, .1)',
    border: '2px solid #fff',
    borderRadius: 8,
    color: '#fff',
    fontWeight: '700',
    fontSize: '18px',
    height: 48,
    margin: '10px 10px 10px 0px',
    width: 256,
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#01923E',
    },
  },
  buttonWhiteBlue: {
    fontFamily: 'MontserratBold',
    backgroundColor: '#fff',
    borderRadius: 8,
    color: '#04679C',
    fontSize: '18px',
    fontWeight: '700',
    height: 48,
    margin: '10px 10px 10px 0px',
    width: 256,
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#04679C',
      borderColor: '#04679C',
      color: '#FFF',
    },
  },
  buttonPay: {
    fontFamily: 'MontserratBold',
    backgroundColor: theme.palette.button.pay,
    borderRadius: 8,
    color: theme.palette.text.pay,
    fontSize: '18px',
    fontWeight: '700',
    height: 48,
    margin: '10px 10px 10px 0px',
    width: 256,
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: theme.palette.text.pay,
      color: theme.palette.button.pay,
    },
  },
  buttonWhiteGreen: {
    fontFamily: 'MontserratBold',
    border: `2px solid ${theme.palette.button.border}`,
    borderRadius: 8,
    color: theme.palette.button.text,
    fontWeight: '700',
    fontSize: '18px',
    height: 48,
    margin: '10px 10px 10px 0px',
    width: 256,
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: theme.palette.button.hover,
      borderColor: theme.palette.button.hover,
      color: theme.palette.secondary.main,
    },
  },
  buttonWhiteBorderBlue: { 
    fontFamily: 'MontserratBold',
    border: `2px solid ${theme.palette.button.serviceButton}`,
    borderRadius: 22,
    color: theme.palette.text.serviceText,
    fontWeight: '700',
    fontSize: '18px',
    height: 48,
    margin: '10px 10px 10px 0px',
    width: 256,
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: theme.palette.button.hoverService,
      borderColor: theme.palette.button.hoverService,
      color: theme.palette.quaternary.main,
    },
  },
  buttonGreenWhite: {
    fontFamily: 'MontserratBold',
    backgroundColor: '#00923D',
    border: '2px solid #fff',
    borderRadius: 8,
    color: '#fff',
    fontWeight: '700',
    fontSize: '18px',
    height: 48,
    margin: '10px 10px 10px 0px',
    width: 256,
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#FFF',
      color: '#00923D',
    },
  },
  buttonBlue: {
    fontFamily: 'MontserratBold',
    backgroundColor: '#04679C',
    border: '2px solid #04679C',
    borderRadius: 8,
    color: '#fff',
    fontWeight: '700',
    height: 48,
    fontSize: '18px',
    margin: '10px 10px 10px 0px',
    width: 256,
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: 'rgba(4, 103, 156, .8)',
      color: '#FFF',
    },
  },
  buttonBlueWhite: {
    fontFamily: 'MontserratBold',
    backgroundColor: '#04679C',
    border: '2px solid #fff',
    borderRadius: 8,
    color: '#fff',
    fontSize: '16px',
    fontWeight: '700',
    height: 48,
    margin: '10px 10px 10px 0px',
    width: 256,
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#01923E',
    },
  },
  buttonCarbonFootprint: {
    fontFamily: 'MontserratBold',
    background: '#00923D',
    border: '2px solid #fff',
    borderRadius: 8,
    color: '#fff',
    fontSize: '18px',
    fontWeight: '700',
    height: '48px',
    margin: '10px 10px 10px 0px',
    width: '256px',
    lineHeight: '19px',
    '&:hover': {
      background: '#fff',
      borderColor: ' #fff',
      color: '#40ad6e',
    },
  },
  [theme.breakpoints.down('sm')]: {
    buttonCarbonFootprint: {
      fontSize: '14px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    buttonCarbonFootprint: {
      fontSize: '12px',
    },
    buttonBlueWhite: {
      fontSize: '12px',
    },
  },
  buttonGreen: {
    height: '48px',
    background: '#00DD9E',
    borderRadius: '31px',
    fontFamily: 'MontserratBold',
    fontStyle: ' normal',
    fontWeight: 700,
    fontSize: '20px',
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'none',
    border: 'none',
    '&:hover': {
      backgroundColor: '#00DD9E',
      color: theme.palette.secondary.main,
    },
    '@media screen and (min-width:576px)': {
      width: '208px',
      lineHeight: '48px',
    },
    '@media screen and (max-width:992px)': {
      width: '100%',
      lineHeight: '18px',
    },
  },
  buttonGreenLarge: {
    minWidth: '312px',
    height: '48px',
    background: '#00DD9E',
    borderRadius: '31px',
    fontFamily: 'MontserratBold',
    fontStyle: ' normal',
    fontWeight: 700,
    fontSize: '20px',
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'none',
    border: 'none',
    '&:hover': {
      backgroundColor: '#00DD9E',
      color: theme.palette.secondary.main,
    },
    '@media screen and (min-width:576px)': {
      width: '312px',
      lineHeight: '24px',
    },
    '@media screen and (max-width:992px)': {
      width: '312px',
      lineHeight: '18px',
    },
  },
  buttonBlueLarge: {
    fontFamily: 'MontserratBold',
    backgroundColor: '#04679C',
    border: '2px solid #04679C',
    borderRadius: 8,
    color: '#fff',
    fontWeight: '700',
    height: 48,
    fontSize: '18px',
    margin: '10px 10px 10px 0px',
    width: '100%',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: 'rgba(4, 103, 156, .8)',
      color: '#FFF',
    },
  },
  buttonBackgroundGreen: {
    fontFamily: 'MontserratBold',
    borderRadius: 20,
    color: '#010C2B',
    fontWeight: '400',
    fontSize: '18px',
    height: 46,
    margin: '10px 10px 10px 0px',
    width: 300,
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0em',
    backgroundColor: '#2CDD00',
    display: 'flex',
    '&:hover': {
      backgroundColor: '#2CDD00',
      color: '#010C2B',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  buttonBarnerMain: {
    fontFamily: 'MontserratBold',
    // border: `2px solid ${theme.palette.button.serviceButton}`,
    borderRadius: 20,
    color: '#010C2B',
    fontWeight: '400',
    fontSize: '18px',
    height: 46,
    margin: '10px 10px 10px 0px',
    width: 300,
    textAlign: 'center',
    backgroundColor: '#06E89F',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    '&:hover': {
      backgroundColor: '#06E89F',
      color: '#010C2B',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  buttonBackgroundWhite: {
    fontFamily: 'MontserratBold',
    borderRadius: 20,
    color: '#010C2B',
    fontWeight: '400',
    fontSize: '18px',
    height: 46,
    margin: '10px 10px 10px 0px',
    width: 300,
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0em',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#010C2B',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  buttonBackgroundGreenTransparent: {
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '4px solid #00DD9E',
    paddingBottom: '2px',
    display: 'inline-block',
    margin: '10px 10px 10px 0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    height: 46,
    width: 'auto',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.02em',
    textAlign: 'center',
    color: theme.palette.text.tertiary,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      maxWidth: '100%',
    },
  },
  buttonBackgroundSoftGreen: {
    fontFamily: 'MontserratBold',
    borderRadius: 20,
    color: '#060f18',
    fontWeight: '400',
    fontSize: '18px',
    height: 46,
    margin: '10px 10px 10px 0px',
    width: 300,
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0em',
    backgroundColor: '#84db4e',
    display: 'flex',
    '&:hover': {
      backgroundColor: '#84db4e',
      color: '#060f18',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  buttonBackgroundTurquoise: {
    fontFamily: 'MontserratBold',
    borderRadius: 20,
    color: '#000e38',
    fontWeight: '400',
    fontSize: '18px',
    height: 46,
    margin: '10px 10px 10px 0px',
    width: 300,
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0em',
    backgroundColor: '#2d76ba',
    display: 'flex',
    '&:hover': {
      backgroundColor: '#2d76ba',
      color: '#000e38',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },

  buttonBackgroundMintGreen: {
    fontFamily: 'MontserratSemiBold',
    color: '#041370',
    fontSize: '18px',
    height: 36,
    margin: '10px 0px 10px',
    minWidth: 101,
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0em',
    backgroundColor: '#06E89F',
    textTransform: 'capitalize',
    padding: '0px 10px ',
    borderRadius: '50px ',
    '&:hover': {
      backgroundColor: '#06E89F',
      color: '041370',
    },
  },

  buttonGreenBlueFont: {
    fontFamily: 'MontserratSemiBold',
    color: '#041370',
    fontSize: '18px',
    height: 40,
    margin: '10px 10px 10px 0px',
    width: 190,
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0em',
    backgroundColor: '#06E89F',
    textTransform: 'capitalize',
    padding: '0px 40px ',
    borderRadius: '50px ',
    '&:hover': {
      backgroundColor: '#06E89F',
      color: '041370',
    },
  },
  buttonBackgroundGreenMint: {
    fontFamily: 'MontserratBold',
    borderRadius: 20,
    color: '#041370',
    fontWeight: '400',
    fontSize: '18px',
    height: 46,
    margin: '10px 10px 10px 0px',
    width: 300,
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0em',
    backgroundColor: '#06E89F',
    display: 'flex',
    '&:hover': {
      backgroundColor: '#06E89F',
      color: '#041370',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  buttonDefaultGreenMint: { 
    fontFamily: 'MontserratBold',
    backgroundColor: '#06E89F',
    borderRadius: 22,
    color: '#041370',
    fontWeight: '700',
    fontSize: '18px',
    height: 48,
    margin: '10px 10px 10px 0px',
    width: 256,
    lineHeight: '19px',
    '&:hover': {
    backgroundColor: '#06E89F',
      color: '#041370',

    },
  },
}));

export default useStyles;
