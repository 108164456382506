import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '1232px',
    height: 500,
    padding: 24,
    margin: 0,
    fontFamily: 'montserrat',
    borderRadius: 20,
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      width: 320,
    },
  },
  textContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  title: {
    margin: 0,
    padding: 0,
    color: '#010C2B',
    fontSize: '3.125rem',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  boldTitle: {
    margin: 0,
    padding: 0,
    color: '#010C2B',
    fontSize: '3.125rem',
    fontWeight: 700,
    lineHeight: 1,
    textAlign: 'left',
  },
  bodyText: {
    color: '#010C2B',
    maxWidth: 580,
    textAlign: 'left',
    fontSize: '1.5rem',
    fontFamily: 'MontserratSemiBold',
  },
  buttonNotifyMe: {
    width: 228,
    height: 46,
    justifyContent: 'space-evenly',
    fontFamily: 'MontserratSemiBold',
    fontSize: 24,
    fontWeight: 500,
    color: '#010C2B',
    backgroundColor: '#2CDD00',
    borderRadius: 20,
    textTransform: 'none',
    '&:focus': {
      backgroundColor: '#2CDD00',
    },
    '&:hover': {
      backgroundColor: '#2CDD00',
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
    },
  },
  img: {
    width: 340,
    height: 300,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonIcon: {
    width: 32,
    height: 30,
  },
}));
export default useStyles;
